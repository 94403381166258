const userAgent = window.navigator.userAgent.toLowerCase();
const isSafari = userAgent.indexOf('safari') != -1

export const fixedBody = () => {
  if (!isSafari) {
    $('body').css({
      position: 'fixed',
      top: `-${window.scrollY}px`
    })
  }
}

export const unFixedBody = () => {
  if (!isSafari) {
    const top = document.body.style.top;
    $('body').css({
      position: '',
      top: ''
    })
    window.scrollTo(0, parseInt(top || '0') * -1);
  }
}
