$(function() {
  if($(window).width() >= 768) {
    $.datetimepicker.setLocale('ja');

    $(".j-datepicker").datetimepicker({
      timepicker:false,
      validateOnBlur: false,
      onChangeDateTime: function(ct, $i) {
        var d = $i.datetimepicker('getValue');
        var v = d.getFullYear();
        v += '-' + ('0' + (d.getMonth() + 1)).slice(-2);
        v += '-' + ('0' + d.getDate()).slice(-2);
        $i.val(v);
      }
    });

    $('.j-datepicker.j-datepicker--company').datetimepicker({
      timepicker:false,
      validateOnBlur: false,
      maxDate: new Date(),
      onChangeDateTime: function(ct, $i) {
        var d = $i.datetimepicker('getValue');
        var v = d.getFullYear();
        v += '-' + ('0' + (d.getMonth() + 1)).slice(-2);
        v += '-' + ('0' + d.getDate()).slice(-2);
        $i.val(v);
      }
    });
    $('.j-datepicker.j-datepicker--company').on('invalid', function (e) {
      if (!this.validity.valid && this.validity.rangeOverflow) {
        e.target.setCustomValidity("明日以降は選択できません"); 
      } else {
        e.target.setCustomValidity(''); 
      }
    })

    $(".j-datepicker.j-datepicker--events").datetimepicker({
      timepicker:false,
      validateOnBlur: false,
      minDate: '2020/01/01',
      maxDate: '2050/12/31',
      onChangeDateTime: function(ct, $i) {
        var d = $i.datetimepicker('getValue');
        var v = d.getFullYear();
        v += '-' + ('0' + (d.getMonth() + 1)).slice(-2);
        v += '-' + ('0' + d.getDate()).slice(-2);
        $i.val(v);
      }
    });
    $('.j-datepicker.j-datepicker--events').on('invalid', function (e) {
      if(!this.validity.valid && this.validity.rangeUnderflow) {
        e.target.setCustomValidity("2020年1月1日以降で検索してください"); 
      } else if(!this.validity.valid && this.validity.rangeOverflow) {
        e.target.setCustomValidity("2050年12月31日以前で検索してください"); 
      } else {
        e.target.setCustomValidity(''); 
      }
    })

    $(".j-datetimepicker").datetimepicker({
      step: 10,
      validateOnBlur: false,
      onChangeDateTime: function(ct, $i) {
        var d = $i.datetimepicker('getValue');
        var v = d.getFullYear();
        v += '-' + ('0' + (d.getMonth() + 1)).slice(-2);
        v += '-' + ('0' + d.getDate()).slice(-2);
        v += 'T' + ('0' + d.getHours()).slice(-2);
        v += ':' + ('0' + d.getMinutes()).slice(-2);
        $i.val(v);
      }
    });
  }
});
