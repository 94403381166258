export default {
  data: () => ({
    filename: null,
  }),
  mounted: function () {
  },
  methods: {
    onFileSelected: function (event) {
      let files = event.target.files;
      this.filename = files[0].name;
      console.log(files[0].name)
    },
  }
}
