import { fixedBody, unFixedBody } from './fixedBody'

$(function () {
  $('.js-servicenav--toggle').on('click', function (event) {
    event.preventDefault();
    const $this = $(this)
    if ($this.hasClass('active')) {
      $('.js-servicenav--content').removeClass('active')
      unFixedBody();
    } else {
      $('.js-servicenav--content').addClass('active')
      fixedBody();
    }
    
    console.log(this)
  })

  $('.js-servicenav--close').on('click', function (event) {
    event.preventDefault();
    $('.js-servicenav--content').removeClass('active')
    unFixedBody();
  })
});
