// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("turbolinks").start()
require("@rails/activestorage").start();
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "./lib/servicemenu";
import "./lib/cocoon";
import "./lib/datetimepicker";
import "./lib/modal";

// vue loader
import Vue from "vue/dist/vue.esm.js";
(function() {
  Vue.directive("init", {
    bind: function(el, binding, vnode) {
      vnode.context[binding.arg] = binding.value;
    },
  });

  var vms = [];
  var options = {};
  var requireContext = require.context("./options", false, /\.js$/);
  requireContext.keys().forEach((key) => {
    let name = key
      .split("/")
      .pop()
      .split(".")
      .shift();
    options[name] = requireContext(key).default;
  });

  // document.addEventListener('turbolinks:load', () => {
  //   let templates = document.querySelectorAll('[data-vue]')
  //   for (let el of templates) {
  //     let vm = new Vue(
  //       Object.assign(options[el.dataset.vue], { el })
  //     )
  //     vms.push(vm)
  //   }
  // })

  // document.addEventListener('turbolinks:visit', () => {
  //   for (let vm of vms) {
  //     vm.$destroy()
  //   }
  //   vms = []
  // })

  $(function() {
    let templates = document.querySelectorAll("[data-vue]");
    for (let el of templates) {
      console.log(Object.assign(options[el.dataset.vue], { el }));
      let vm = new Vue(Object.assign(options[el.dataset.vue], { el }));
      vms.push(vm);
      console.log(options);
    }
  });
})();

$(function() {
  $(".js--toggle-active").on("click", function() {
    const $this = $(this);
    $this.hasClass("active") ? $this.removeClass("active") : $this.addClass("active");
    console.log($this);
  });

  $(".js--pagination-container").paginathing({
    perPage: 9,
    firstLast: false,
    prevText: "<",
    nextText: ">",
    activeClass: "is-active",
    ulClass: "p-vbtimes-pagination",
    liClass: "p-vbtimes-pagination__item",
  });

  // if (Swiper) {
  //   new Swiper('.swiper-container', {
  //     loop: true,
  //     paginationClickable: true,
  //     pagination: '.swiper-pagination',
  //     nextButton: '.swiper-button-next',
  //     prevButton: '.swiper-button-prev',
  //   })
  // }
});

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);
// resizeイベントの取得
window.addEventListener("resize", () => {
  // あとは上記と同じスクリプトを実行
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

$(function() {
  $(".js-headernav__menu-dropdown").on("click", function() {
    const $this = $(this);
    console.log($this);
    if ($this.hasClass("active")) {
      $this.removeClass("active");
      $this.next().slideUp();
    } else {
      $this.addClass("active");
      $this.next().slideDown();
    }
  });

  $('input[name="company[employee_number]"]').on("invalid", function(e) {
    if (!this.validity.valid && this.validity.rangeUnderflow) {
      e.target.setCustomValidity("従業員数は1以上にしてください。");
    } else {
      e.target.setCustomValidity("");
    }
  });
});
