$(function () {
  const $industryModal = $('.js-modal__industryModal');
  let array_industry = {};
  $industryModal.find('.js-modal__industryModal__clear').on('click', function() {
    reset_input_industry();
  });
  $industryModal.find('input[type="checkbox"]').on('click', function () {
    const id = $(this).val();
    const text = $(this).data('text');
    array_industry[id] = text;
    if ($(this)[0].checked == false) delete array_industry[id];
    apply_input_industry(array_industry);
  });
  $industryModal.find('input[type="checkbox"]').each(function () {
    const id = $(this).val();
    const text = $(this).data('text');
    const is_checked = $(this)[0].checked == true
    if (is_checked) array_industry[id] = text;
    apply_input_industry(array_industry);
  });
  function apply_input_industry(array_industry) {
    $('.js-industryOutput').val(Object.values(array_industry));
  }
  function reset_input_industry() {
    const checkboxes = $industryModal.find('input[type="checkbox"]');
    checkboxes.prop('checked', false);
    apply_input_industry(array_industry = {});
  }




  const $placeModal = $('.js-modal__placeModal');
  let array_place = {};
  $placeModal.find('.js-modal__placeModal__clear').on('click', function () {
    reset_input_place();
  });
  $placeModal.find('input[type="checkbox"]').on('click', function() {
    const id = $(this).val();
    const text = $(this).data('text');
    array_place[id] = text;
    if ($(this)[0].checked == false) delete array_place[id];
    apply_input_place(array_place);
  });
  $placeModal.find('input[type="checkbox"]').each(function () {
    const id = $(this).val();
    const text = $(this).data('text');
    const is_checked = $(this)[0].checked == true
    if (is_checked) array_place[id] = text;
    apply_input_place(array_place);
  });
  function apply_input_place(array_place) {
    $('.js-placeOutput').val(Object.values(array_place));
  }
  function reset_input_place() {
    const checkboxes = $placeModal.find('input[type="checkbox"]');
    checkboxes.prop('checked', false);
    apply_input_place(array_place = {})
  }
});
